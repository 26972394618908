import type { LoginForm, RegisterForm, AuthResponse } from '~/types/auth'

export const useStoreAuth = defineStore('auth', () => {
  const authCreatorCookie = useCookie('auth_creator', {
    expires: new Date(new Date().getTime() + 1000 * 3600 * 24 * 365),
    sameSite: 'none',
    secure: true,
    path: '/'
  })

  const authCreatorCookieFallback = ref<string | null>(null)

  const isAuthenticated = computed(() => !!authCreatorCookie.value || !!authCreatorCookieFallback.value)

  const login = async (companyId: number, loginForm: LoginForm) => {
    const { email, password, token } = loginForm
    const res: AuthResponse = await useNuxtApp().$api(`/api-token-auth-creator/`, {
      method: 'POST',
      body: {
        company_id: companyId,
        email,
        password,
        'captcha-token': token
      }
    })
    authCreatorCookie.value = res.token
    authCreatorCookieFallback.value = res.token
    return res
  }

  const register = async (companyId: number, registerForm: RegisterForm) => {
    const { email, password, token, firstname } = registerForm
    const res: AuthResponse = await useNuxtApp().$api(`/api/creators/`, {
      method: 'POST',
      body: {
        first_name: firstname,
        company_id: companyId,
        email,
        password,
        'captcha-token': token
      }
    })
    authCreatorCookie.value = res.token
    authCreatorCookieFallback.value = res.token
    return res
  }

  const { setHasCustomerId } = useStoreStore()

  const authenticateFromStore = async (companyId: number, customerId: string) => {
    const res: AuthResponse = await useNuxtApp().$api(`/api/creator/companies-creators/from-external-store/`, {
      method: 'POST',
      body: {
        company_id: companyId,
        customer_id: customerId
      }
    })
    authCreatorCookie.value = res.token
    authCreatorCookieFallback.value = res.token
    setHasCustomerId(companyId, parseInt(customerId, 10))

    return res
  }

  const logout = () => {
    authCreatorCookie.value = null
    authCreatorCookieFallback.value = null
  }

  const requestNewPassword = async (companyId: number, email: string) => {
    return await useNuxtApp().$api(`/api/creator/password_forgotten/`, {
      method: 'POST',
      body: {
        email,
        company_id: companyId
      }
    })
  }

  const checkTokenNewPassword = async (token: string) => {
    await useNuxtApp().$api(`/api/creator/check_token_password_forgotten/?token_forgotten_password=${token}`)
  }

  const resetPassword = async (password: string, token: string) => {
    const { siteKey } = useRuntimeConfig().public.recaptcha
    return await useNuxtApp().$api(`/api/creator/password/`, {
      method: 'PUT',
      body: {
        password,
        token_forgotten_password: token,
        siteKey
      }
    })
  }

  const setToken = (value: string) => (authCreatorCookie.value = value)

  return {
    login,
    logout,
    register,
    requestNewPassword,
    checkTokenNewPassword,
    resetPassword,
    isAuthenticated,
    setToken,
    authenticateFromStore,
    authCreatorCookieFallback
  }
})


// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr",
  "de",
  "nl",
  "pl",
  "es",
  "it"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en-US.ts", load: () => import("../locales/en-US.ts" /* webpackChunkName: "locale__opt_build_repo_locales_en_US_ts" */), cache: true }],
  "fr": [{ key: "../locales/fr-FR.ts", load: () => import("../locales/fr-FR.ts" /* webpackChunkName: "locale__opt_build_repo_locales_fr_FR_ts" */), cache: true }],
  "de": [{ key: "../locales/de-DE.ts", load: () => import("../locales/de-DE.ts" /* webpackChunkName: "locale__opt_build_repo_locales_de_DE_ts" */), cache: true }],
  "nl": [{ key: "../locales/nl-NL.ts", load: () => import("../locales/nl-NL.ts" /* webpackChunkName: "locale__opt_build_repo_locales_nl_NL_ts" */), cache: true }],
  "pl": [{ key: "../locales/pl-PL.ts", load: () => import("../locales/pl-PL.ts" /* webpackChunkName: "locale__opt_build_repo_locales_pl_PL_ts" */), cache: true }],
  "es": [{ key: "../locales/es-ES.ts", load: () => import("../locales/es-ES.ts" /* webpackChunkName: "locale__opt_build_repo_locales_es_ES_ts" */), cache: true }],
  "it": [{ key: "../locales/it-IT.ts", load: () => import("../locales/it-IT.ts" /* webpackChunkName: "locale__opt_build_repo_locales_it_IT_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": true
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "locales/en-US.ts"
      ]
    },
    {
      "code": "fr",
      "name": "Français",
      "files": [
        "locales/fr-FR.ts"
      ]
    },
    {
      "code": "de",
      "name": "Deutsch",
      "files": [
        "locales/de-DE.ts"
      ]
    },
    {
      "code": "nl",
      "name": "Nederlands",
      "files": [
        "locales/nl-NL.ts"
      ]
    },
    {
      "code": "pl",
      "name": "Polski",
      "files": [
        "locales/pl-PL.ts"
      ]
    },
    {
      "code": "es",
      "name": "Español",
      "files": [
        "locales/es-ES.ts"
      ]
    },
    {
      "code": "it",
      "name": "Italien",
      "files": [
        "locales/it-IT.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales/",
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": true,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "en",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "locales/en-US.ts"
      }
    ]
  },
  {
    "code": "fr",
    "name": "Français",
    "files": [
      {
        "path": "locales/fr-FR.ts"
      }
    ]
  },
  {
    "code": "de",
    "name": "Deutsch",
    "files": [
      {
        "path": "locales/de-DE.ts"
      }
    ]
  },
  {
    "code": "nl",
    "name": "Nederlands",
    "files": [
      {
        "path": "locales/nl-NL.ts"
      }
    ]
  },
  {
    "code": "pl",
    "name": "Polski",
    "files": [
      {
        "path": "locales/pl-PL.ts"
      }
    ]
  },
  {
    "code": "es",
    "name": "Español",
    "files": [
      {
        "path": "locales/es-ES.ts"
      }
    ]
  },
  {
    "code": "it",
    "name": "Italien",
    "files": [
      {
        "path": "locales/it-IT.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"

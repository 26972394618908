<script setup lang="ts">
  import Error404 from '@/assets/error/404.svg'
  import type { NuxtError } from '#app'
  import { useI18n } from 'vue-i18n'

  const props = defineProps<{ error: NuxtError }>()

  const { t } = useI18n()

  onMounted(() => {
    useTrack().track('page-404-error')
  })

  window.parent.postMessage({ scrollTop: true, isWeWiink: true }, '*')
  document.title = t('Error.title')

  const showReloadLink = computed(() => {
    const { data } = props.error

    if (!data) return true

    const { reload } = data as { reload?: boolean }

    return reload ?? true
  })

  const onReload = () => {
    location.reload()
  }
</script>

<template>
  <div class="app-error">
    <Error404 class="app-error--image" />
    <p class="app-error--message text-secondary-heading-2">
      {{ error?.statusMessage ?? $t('Error.generic') }}
    </p>
    <button
      v-if="showReloadLink"
      class="text-primary-heading-2 app-error--button"
      @click="onReload"
    >
      {{ $t('Error.link') }}
    </button>
  </div>
</template>

<style scoped>
  .app-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 40px;
    margin-top: 100px;

    &--image,
    &--message {
      max-width: 50%;
    }

    &--message {
      text-align: center;
      word-wrap: break-word;
    }

    &--button {
      background-color: black;
      color: white;
      padding: 14.5px 24px;
      text-decoration: none;
      height: 50px;
    }
  }
</style>

export const useStoreApp = defineStore('app', () => {
  const isPreview = ref(false)
  const isModalFullPage = ref(false)
  const isAppEmbedded = ref(false)
  const modalsOpenedCount = ref(0)
  const appWidth = ref(0)

  const domModalsCount = ref(0)

  const setIsPreview = (value: boolean) => {
    isPreview.value = value
  }

  const isMobile = computed(() => appWidth.value < 768)

  const companyId = ref(0)

  return {
    isPreview,
    setIsPreview,
    companyId,
    isModalFullPage,
    isAppEmbedded,
    modalsOpenedCount,
    isMobile,
    appWidth,
    domModalsCount
  }
})
